<template>
<div class="tw-mt-12 lg:tw-w-7/12 tw-w-full">
<!--      <div class="charts mt-5 mb-5" id="chart" >-->
<!--        <span class="actions">functional skill</span>-->
<!--        <apexchart class="basic-chart" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>-->
<!--      </div>-->
<div>
  <span  class="actions">Aptitude Test Report</span>
  <v-skeleton-loader :boilerplate="true" type="article" v-if="loading"></v-skeleton-loader>
  <div v-else-if="!loading && !psychometricResult.length" class="tw-my-5 md:tw-ml-48">
    <ReportEmptyState/>
    <span class="no-report">No report generated for this candidate yet</span>
  </div>
  <div v-else class="tw-mt-8">
    <div  class="charts mt-12 mb-5 pieChart" id="chart2">
      <div class="chart-evaluation-wrapper">
        <div class="chart-evaluation">
          <span class="cognitivePerformance">Cognitive <br/>Performance</span>
          <span class="value">{{totalPercentile}}%</span>
        </div>
      </div>
      <div  class="pie">
        <apexchart class="basic-chart apex-label" type="donut" :options="options" :series="psychometricResult"></apexchart>
      </div>

    </div>
  </div>
</div>
<div class="tw-my-10">
  <span class="actions ">Behavioural Assessment Report</span>
  <v-skeleton-loader :boilerplate="true" type="article" v-if="loading"></v-skeleton-loader>
  <div v-else-if="!loading && !personalityResult.length" class="tw-my-5 md:tw-ml-48">
    <ReportEmptyState/>
    <span class="no-report">No report generated for this candidate yet</span>
  </div>
  <v-row v-else class="tw-mt-8" v-for="(data, i) in personalityResult" :key="i">
    <v-col cols="12" md="3" sm="12">
      <div class="tw-flex tw-justify-between">
        <p class="personality-name">{{data.name}} </p>
        <span class="personality-score">{{ Math.ceil(data.percentage_score) }}%</span>
      </div>
      <v-progress-linear
          :value="Math.ceil(data.percentage_score)"
          :color="getGradeColor(data.percentage_score)"
          height="6"
          rounded
      ></v-progress-linear>

    </v-col>
    <v-col cols="12" md="1" sm="0"></v-col>
    <v-col cols="12" md="7" sm="12">
      <span class="personality-description">{{data.interpretation}}</span>
    </v-col>
  </v-row>

</div>


</div>
</template>

<script>
// import {getCandidateReport} from "@/services/api/APIService";
import ReportEmptyState from "@/components/UIComponents/reusablesIcon/ReportEmptyState";

export default {
name: "Evaluation",
  components: {ReportEmptyState},
  props:{
    loading:[Boolean],
    skillResult:[Array],
    personalityResult:[Array],
    psychometricResult:[Array],
    totalPercentile:[Number,String],
    options:[Object]
  },
  data: ()=> {
  return{

    series: [],
    chartOptions: {
      chart: {
        height: 350,
        width: '100%',
        fontFamily:'DM Sans',
        toolbar: {
          show: false
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          columnWidth: '2%',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['Visual Design', 'Figma', 'User Research'
        ],
      },
      colors: ['#8F47F4']
    },
  }
  },

  computed:{
      getGradeColor(){
        let color
        return function(score){
          if(score<=25){
            color = '#FF9900'
          }
          else if(score>25&&score<=50){
            color = '#0BCA7A'

          }
          else if(score>50&& score<=75){
            color = '#008EEF'

          }
          else {
            color = '#5D18BE'

          }
          return color

        }
      }
  },
}
</script>

<style scoped lang="scss">
.charts{
  border-bottom: 1px solid #D0DCE4 ;
  margin-bottom: 2rem;
}
.basic-chart {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 140%;
  align-items: center;
  letter-spacing: 0.01em;
  color: #475661;

}
.actions{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;

}
.resultSummary{
  font-family: DM Sans;
  h3{
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #1E323F;
    margin-bottom: 1rem;

  }
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #475661;
  }

}
.personality{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.pieChart{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 780px) {
    display: flex;
    flex-direction: row;
  }
}
.pie{
  @media (min-width: 860px) {
    width: 100%;
    max-width: 600px;
  }
  @media (max-width: 1280px) {
    margin-bottom: -12rem;
  }
  @media (min-width: 1920px) {
    margin-bottom: -12rem;
  }
}
.value{
  margin-top: 1.5rem;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #1E323F;
  @media (max-width: 1280px) {
    margin-top: 1.5rem;
  }
  @media (max-width: 1024px) {
    margin-top: 1.5rem;
  }
  @media (max-width: 779px){
    margin-top: 0;
  }


}
.chart-evaluation{
  display: flex;
  justify-content: space-between;

  @media (min-width: 780px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.cognitivePerformance{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #475661;

}
.personality-name{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  color: #1E323F;
}
.personality-score{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #1E323F;
}
.personality-description{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}


</style>
